/*========= User list table ==========*/
.action-icon {
  visibility: visible;
	margin: 0;
	li {
		font-size: 20px;
		a {
			color: $gray-700;
			&:hover {
				color: $blue;
			}
		}
	}
}

/*========= User list ==========*/
.list-title {
	background: $gray-300;
	color: $gray-600;
	padding: 5px;
}
.simple-user-list {
	li {
		margin-bottom: 1rem;
	}
}
.content-block {
	padding-top: 1rem;
}
.primary-tp-layer {
	@include background-opacity($blue, 0.7);
	opacity: 0;
	transition: all 0.3s ease-in-out 0s;
}
.white-tp-layer {
	@include background-opacity($white, 0.8);
	opacity: 0;
	transition: all 0.3s ease-in-out 0s;
}
.overlay-wrap:hover .primary-tp-layer,
.overlay-wrap:hover .white-tp-layer {
	opacity: 1;
}
.pos-center {
	&:before {
		content: "";
		display: inline-block;
		height: 100%;
		vertical-align: middle;
	}
}
.center-holder {
	display: inline-block;
	height: auto;
	padding: 20px;
	vertical-align: middle;
	width: 90%;
}
.content-block-list {
	padding-top: 5px;
}
.user-list-wrapper {
	address {
		padding-left: 75px;
		span {
			margin-bottom: 10px;
		}
	}
	.panel {
		margin: 0;
		.panel-heading .accordion-toggle::after {
			top: 35px;
		}
	}
	.filler-table {
		background-color: $gray-300;
		padding: 20px;
		border-bottom: border-width solid $input-border-color;
	}
}
.circle {
	border-radius: 100%;
	color: $white;
}

/*========= User Profile style css ==========*/
.user-profile-wrapper {
	padding: 0 30px;
	margin-top: -10rem;
	.profile-icon-head {
		width: 110px;
		float: left;
	}
	.profile-colon {
		width: 45px;
		float: left;
	}
	.profile-icon-desp {
		float: left;
		width: calc(100% - 155px);
		word-wrap: break-word;
	}
	.notification-list {
		li {
			margin-bottom: 15px;
		}
	}
	.top-block {
		height: 10rem;
    background: currentColor;
	}
	.bottom-block {
		@include border(1px solid, $input-border-color, bottom left right);
		margin-top: -5rem;
		.profile-thumb {
			img {
				box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.5);
			}
		}
		.thumb-content {
			h2 {
				font-size: 26px;
			}
			a {
				color: $gray-800;
			}
		}
		.user-account-list {
			li {
				@include border(1px solid, $input-border-color, top);
				&:nth-child(2n+1) {
					background-color: $gray-300;
				}
			}
			a {
				color: $gray-800;
				padding: 10px;
				display: block;
			}
		}
		.social-widget {
			background: $gray-800;
			padding: 20px;
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
			li {
				a {
					color: $white;
				}
			}
		}
	}
	.timeline-meta {
		a {
			color: $gray-800;
		}
	}
	.contact-list {
		@include border(1px solid, $input-border-color, bottom right left top);
		.contact-head {
			background: $gray-300;
			.badge {
				line-height: 30px;
			}
		}
		li {
			@include border(1px solid, $input-border-color, bottom);
			padding: 15px 10px;
		}
	}
	.admin-setting {
		position: absolute;
		top: 10px;
		right: 30px;
	}
	.account-setting {
		border-radius: 5px;
		overflow: hidden;
	}
	.notifi-heading {
		background-color: $orange;
		border-radius: 5px;
		color: $white;
		padding: 15px 10px;
		h3 {
			margin: 0;
		}
	}
}

/*======== User Contact Css Style =========*/
.thumb-box {
	img {
		box-shadow: 0 0 0 20px hsl(214, 100%, 45%), 0 0 0 30px hsl(211, 80%, 72%), 0 0 0 45px hsl(212, 81%, 94%);
		margin-bottom: 5rem;
	}
}
#call-popup {
	.modal-content {
		padding: 60px 20px;
	}
}
.card-wrap {
	background-color: $block-bg;
	border: $border-width solid $input-border-color;
	padding: $block-space-x $block-space-y;
	overflow: hidden;
}
.user-contact-wrapper {
	address {
		margin: 0;
	}
	.card-action-link {
		background-color: $gray-300;
		padding: 10px;
		@include border(1px solid, $input-border-color, bottom right left);
		ul {
			margin: 0;
		}
	}
	.card-wrapper {
		transition: all 0.3s ease-in-out 0s;
		&:hover {
			border-color: $gray-400;
			box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
		}
	}
}
.media-card {
	@include border(1px solid, $input-border-color, bottom right left top);
	.media-body {
		padding: 20px 10px;
	}
}

//==========User Responsive Css ==============//
@media (min-width: 320px) and (max-width: 1500px) {
	.user-profile-wrapper {
		margin-top: -70px;
	}
}
@media (min-width:1200px) and (max-width: 1340px) {
	.center-holder {
		padding: 0;
	}
}
@media (min-width: 320px) and (max-width: 1765px) {
	.user-contact-wrapper {
		.simplifie-user-grid {
			.card {
				.d-flex.align-items-start {
					text-align: center;
					width: 100%;
					img {
						width: 100%;
					}
				}
				.d-flex {
					display: inline-block !important;
				}
			}
		}
	}
}
@media(max-width:560px) {
	.user-contact-wrp {
		.d-flex.justify-content-start,
		.d-flex.align-items-start.flex-column,
		.card-action-link.text-right {
			display: block !important;
			margin: 0 !important;
			text-align: center !important;
		}
	}
	.chk-content-wrapper {
		.img-pro-head {
			display: none;
		}
		.user-profile-wrapper {
			padding-left: 0px;
			padding-right: 0px;
		}
		.user-profile-wrapper {
			margin-top: 0px;
		}
	}
}
@media (max-width: 500px) {
	.user-profile-wrapper {
		.profile-icon-head {
			width: 90px;
		}
		.profile-colon {
			width: 30px;
		}
		.profile-icon-desp {
			width: calc(100% - 120px);
		}
	}
}
[dir="rtl"] {
	.profile-icon-head {
		float: right;
	}
	.profile-icon-desp {
		float: right;
	}
}
/*==========User Profile V2===========*/
.user-profile-v2 {
	.profile-thumb {
		width: 160px;
	}
	.profile-content {
		width: calc(100% - 160px);
	}
	.img-thumb {
		width: 80px;
	}
	.child-thumb {
		width: 60px;
	}
	.follow-img-wrap {
		width: 40px;
	}
	.card-content {
		width: calc(100% - 60px);
	}
	.bg-overlay {
		background: rgba(0, 0, 0, 0.5);
	}
}
@media (max-width: 768px) {
	.user-profile-v2 {
		.profile-thumb {
			width: 100%;
			margin-bottom: 1rem;
			text-align: center;
		}
		.profile-content {
			width: 100%;
			text-align: center;
		}
		.card-content {
			width: 100%;
		}
	}
}

/*==========User grid list===========*/
.user-grid-list-wrap {
	.grid-list-card {
		.user-info {
			.img-thumb {
				width: 50px;
			}
			.sec-title {
				width: calc(100% - 75px);
				min-width: 90px;
			}
		}
		.resp-width {
			min-width: 105px;
		}
	}
}

/*==========User Manage List===========*/
.user-manage-list,
.contact-list {
	.chk-block {
		.chk-block-title {
			.form-field {
				min-width: 200px;
			}
		}
		.chk-block-content {
			.fa-circle {
				font-size: 12px;
				margin-top: 4px;
				&.online {
					color: $success;
				}
				&.offline {
					color: $danger;
				}
			}
			.badge {
				font-size: 11px;
				padding: 4px 7px;
			}
			table td {
				vertical-align: middle;
			}
		}
	}
	table {
		border-top: 1px solid #DEE4E8;
		border-bottom: 1px solid #DEE4E8;
		th {
			vertical-align: middle;
			border: 1px solid transparent;
			border-top: 1px solid transparent;
			padding: 1rem;
		}
		td {
			border: 1px solid transparent;
		}
	}
	.user-grid-chkbox,
	.contact-grid-chkbox {
		.text-relative {
			position: relative;
			margin-bottom: 0.3rem;
		}
		&.custom-control {
			padding: 0px 10px;
			min-height: inherit;
		}
		.custom-control-label {
			vertical-align: text-top;
			position: static;
			&:after {
				left: 0;
			}
			&:before {
				left: 0;
			}
		}
	}
}
.userlist-popup {
	width: $modal-def-width;
	height: auto;
}
@media (max-width:767px) {
	.userlist-popup {
		width: 100%;
	}
}
