/*======= calender =========*/
.chankya-btn-group {
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
	padding: 2rem 0;
	.mat-icon {
		margin-left: 0.5rem;
		margin-right: 0.5rem;
		cursor: pointer;
	}
}
.calendar-wrapper .chankya-btn-group {
	direction: ltr;
}
.chankya-cal-head {
	line-height: 50px;
	margin-top: 0.5rem;
}
.modal-content {
	-moz-box-direction: normal;
	-moz-box-orient: vertical;
	background-clip: padding-box;
	background-color: $white;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 0.3rem;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	outline: 0 none;
	padding: 2rem;
	overflow-wrap: break-word;
	position: relative;
}
.close {
	position: absolute;
	top: 1.5rem;
	right: 1.5rem;
	.mat-icon {
		cursor: pointer;
		display: inline-block;
		padding-top: 0.3rem;
	}
}
.modal-confirm-btn {
	border: 1px solid $gray-400;
	cursor: pointer;
}
.app-dark ms-full-calendar .modal-content .mat-icon {
	border-color: $black;
	color: $black;
}
.app-dark ms-full-calendar .modal-body {
	color: $black;
}
.chankya-cal-head {
	padding: 10px 0;
}