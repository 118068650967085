/*========= Scaffolding ===========*/
html {
	font-size: $html-font-size;
}
@media(max-width:1365px) {
	html {
		font-size: $html-font-size - 1px;
	}
}
@media(max-width:1281px) {
	html {
		font-size: $html-font-size - 1px;
	}
}
@media(max-width:992px) {
	html {
		font-size: $html-font-size - 2px;
	}
}
html,
body {
	width: 100%;
	height: 100%;
	position: relative;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-touch-callout: none;
	min-height: 100%;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
	background-color: $body-bg;
}
body {
	font-size: $font-size-base;
}
[tabindex='-1']:focus {
	outline: none;
}
select,
button,
textarea,
input {
	vertical-align: baseline;
}
html,
body {
	&[dir=rtl],
	&[dir=ltr] {
		unicode-bidi: embed
	}
}
bdo[dir=rtl] {
	direction: rtl;
	unicode-bidi: bidi-override;
}
bdo[dir=ltr] {
	direction: ltr;
	unicode-bidi: bidi-override;
}
a {
	cursor: pointer;
	&:hover {
		cursor: pointer;
	}
}
/*=========== Common file css =============*/
.badge:empty {
	display: inline-block;
}
.bg-dark {
	color: $white;
}
.overlay-wrap {
	position: relative;
}
.overlay-content {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
address {
	span {
		display: block;
	}
}
.pos-relative {
	position: relative;
}
.btn-group-justified {
	display: flex;
	width: 100%;
	.btn,
	.btn-group {
		flex: 1;
		.btn {
			width: 100%;
		}
		.dropdown-menu {
			left: auto;
		}
	}
}
.thumbnail {
	border: 1px solid $input-border-color;
	border-radius: 0;
	line-height: 1.42857;
	padding: 4px;
	transition: border 0.2s ease-in-out 0s;
}
.has-feedback {
	position: relative;
	.form-control-feedback {
		position: absolute;
		top: 10px;
		right: 10px;
	}
}
/*========= Breadcrumb =========*/
.breadcrumb {
	text-transform: capitalize;
	background-color: rgba(0, 0, 0, 0);
	.breadcrumb-item.active {
		color: $blue;
	}
}