/*========== To DO List Style ==========*/
.to-do-list-wrap {
	height: 205px;
	overflow: hidden;
	padding: 0.2rem;
	li {
		position: relative;
		i {
			position: absolute;
			right: 20px;
			cursor: pointer;
			opacity: 0;
			transition: all 0.3s ease-in-out 0s;
			font-size: 1.2rem;
         top:5px;
		}
		&:hover {
			i {
				opacity: 1;
			}
		}
	}
}
.todo-widget {
	.input-group {
		.input-group-btn .btn {
			height: calc(2.25rem + 2px);
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
}
.custom-checkbox {
	.custom-control-indicator {
		border-radius: 0;
		top: 0;
		width: 1.5rem;
		height: 1.5rem;
		background-color: transparent;
		border: 1px solid $block-border-color;
	}
}
.table-middle {
	thead,
	tbody,
	tfoot {
		tr {
			td,
			th {
				vertical-align: middle;
			}
		}
	}
}
.project-status-table{
   .member-list,.proj-name{
      min-width:154px;
      display: flex;
      white-space: no-wrap;
   }
}
.bg-light {
	color: $gray-700 !important;
}
/*========== Card Borders Style ==========*/
.stat-border-primary {
	border-top: 3px solid $blue;
	box-shadow: 0 20px 8px -18px rgba(0, 0, 0, 0.3) inset;
}
.stat-border-success {
	border-top: 3px solid $green;
	box-shadow: 0 20px 8px -18px rgba(0, 0, 0, 0.3) inset;
}
.stat-border-warning {
	border-top: 3px solid $orange;
	box-shadow: 0 20px 8px -18px rgba(0, 0, 0, 0.3) inset;
}
.stat-border-inverse {
	border-top: 3px solid $gray-800;
	box-shadow: 0 20px 8px -18px rgba(0, 0, 0, 0.3) inset;
}
.stat-border-muted {
	border-top: 3px solid $text-muted; // box-shadow: 0 20px 8px -18px rgba(0, 0, 0, 0.3) inset;
}
.base-card {
	h2,
	h4,
	.dash-icon i {
		color: $gray-700;
	}
}
/*========== Chat Css Style ==========*/
.inner-tab {
	height: 100%;
	border: 1px solid $input-border-color;
	border-right: 0;
}
.chankya-absolute {
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 54%;
}
.chat-content {
	padding: 1rem;
	color: $white;
	&.receiver {
		background-color: rgba($chat-block-odd-bg, 0.2);
		color: $body-color;
	}
	&.sender {
		background-color: rgba($chat-block-even-bg, 0.8);
		color: $white;
	}
	p {
		&:last-child {
			margin: 0;
		}
	}
}
/*========== Dashborad chart ==========*/
.overlay-bg {
	background-color: rgba(0, 0, 0, 0.07);
}
.over-hidden {
	overflow: hidden;
}
.height-130 {
	height: 130px;
}
.height-100 {
	height: 100px;
}
.height-300 {
	height: 300px;
}
.ngx-charts-outer {
	@extend .clearfix;
}
.chankya-custom-legends {
	.advanced-pie-legend .legend-items-container .legend-items .legend-item {
		text-align: center;
	}
}
.card-head {
	border-radius: $border-radius;
	box-shadow: $card-box-shadow;
	z-index: 9;
}
.hover-up-content {
	.d-block.fa-2x {
		font-size: 26px;
	}
}
.dash-icon {
	i {
		vertical-align: -26px;
	}
}
.fs-26 {
	font-size: 26px;
}
.custom-chart-height{
   height: 350px !important;
}
@media (max-width:767px){
   .custom-chart-height {
      height: 270px !important;  
  }
}
@media (max-width:450px){
   .custom-chart-height {
      height: 200px !important;  
  }
}
/*========== Tooltip Effect for dashboard ==========*/
.tooltip-link {
	background: $white;
	border-radius: 3px;
	box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
	color: $gray-800 !important;
	font-size: 0.9rem;
	left: -32px;
	min-width: 100px;
	opacity: 0;
	padding: 6px;
	position: absolute;
	top: 40px;
	visibility: hidden;
	transform: translateY(10px);
	transition: all 0.3s ease-in-out 0s;
	&:after {
		bottom: 100%;
		left: 50%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(255, 255, 255, 0);
		border-bottom-color: $white;
		border-width: 8px;
		margin-left: -8px;
	}
}
.card-actions {
	left: 0;
	position: absolute;
	right: 0;
	text-align: center;
	top: 30px;
	z-index: 1;
	> a {
		color: inherit;
		display: inline-block;
		margin: 0 0.3rem;
		position: relative;
		width: 40px;
		transition: all 0.5s ease-in-out 0s;
		&:hover {
			.tooltip-link {
				transform: translateY(0px);
				opacity: 1;
				visibility: visible;
			}
		}
	}
	a.active {
		background: rgba(0, 0, 0, 0.3);
		padding: 0.3rem;
		border-radius: 5px;
	}
}
div.ngx-charts-outer.ng-trigger.ng-trigger-animationState {
	float: left;
	overflow: hidden;
}
.card-body {
	overflow: hidden;
}
//Latest Post
.author-image {
	background-color: $white;
	border-radius: 70px;
	height: 90;
	margin-top: -30px;
	padding: 5px;
	position: relative;
	width: 90px;
}
//Feeds
.feed-block {
	li {
		.square-40 {
			-webkit-transition: -webkit-transform 300ms, box-shadow 300ms, opacity 300ms;
			-moz-transition: -webkit-transform 300ms, box-shadow 300ms, opacity 300ms;
			-ms-transition: -webkit-transform 300ms, box-shadow 300ms, opacity 300ms;
			-o-transition: -webkit-transform 300ms, box-shadow 300ms, opacity 300ms;
			transition: -webkit-transform 300ms, box-shadow 300ms, opacity 300ms;
			box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
			-webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
			transform: translate3d(0, 0, 0);
			-webkit-transform: translate3d(0, 0, 0);
      }
      + span{
         width: calc(100% - 40px);
      }
		&:hover {
			.square-40 {
				-webkit-transform: translateY(-6px);
				-moz-transform: translateY(-6px);
				transform: translateY(-6px);
				box-shadow: 0 20px 20px rgba(0, 0, 0, 0.1);
				-moz-box-shadow: 0 20px 20px rgba(0, 0, 0, 0.1);
				-webkit-box-shadow: 0 20px 20px rgba(0, 0, 0, 0.1);
			}
		}
	}
}
//author card
.author-card{
   .media{
      width: 100%;
   }
   .media-body {
      flex: auto;
      width: calc(100% - 140px);  
  }
}
@media(max-width:768px) {
	.author-card {
		.media {
			img {
				width: 80px;
				height: 80px;
			}
		}
	}
}
//base card border
.base-card-border {
	padding: 2px 2px 2px 10px;
	.bg-white {
		color: #2C3252;
	}
}
//chat window
.chat-window--open .chat-window {
	opacity: 1;
	visibility: visible;
	transform: translateY(0);
}
.chat-window {
	opacity: 0;
	visibility: hidden;
	width: 350px;
	padding-bottom: 1rem;
	background: #fff;
	position: fixed;
	transition: opacity 0.3s ease-in-out 0s, transform 0.3s ease-in-out 0s, all 0.3s ease-in-out 0s;
	bottom: 0;
	transform: translateY(20%);
	box-shadow: -6px 8px 11px 0 rgba(0, 0, 0, 0.33);
	right: 25%;
	z-index: 9;
	.user-name-chat {
		padding: 0.7rem 1rem;
	}
	.chat-inner {
		height: 365px;
		overflow-x: hidden;
		overflow-y: auto;
		padding: 1rem;
	}
	ul {
		padding: 0;
		list-style: none;
	}
	.user-image {
		border-radius: 100%;
	}
	.chat-user-img {
		display: block;
	}
	.msg-box {
		border-radius: 5px;
		background-color: rgba($chat-block-odd-bg, 0.2);
		color: $body-color;
	}
	.my-msg {
		.msg-box {
			background-color: rgba($chat-block-even-bg, 0.8);
			color: $white;
		}
		.chat-user-img {
			order: 2;
		}
		span,
		.time {
			text-align: right;
		}
	}
	.send-msg-chat {
		input[type="text"] {
			border-radius: 0;
		}
	}
}
//Social Grid
.icon-social {
	width: 90px;
	height: 90px;
	float: left;
	background-color: rgba(0, 0, 0, 0.2);
	i {
		font-size: 40px;
		text-align: center;
	}
}
.icon-info-social {
	width: calc(100% - 90px);
}
.name-social {
	font-size: 1.625rem;
	margin-bottom: 5px;
}
.number-social {
	font-size: 1.2rem;
	margin-bottom: 5px;
}
//Section slider
.caption-overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0) linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8));
	background: rgba(0, 0, 0, 0) -webkit-linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8));
}
@media(max-width:1500px) {
	.name-social {
		font-size: 1.2rem;
	}
	.number-social {
		font-size: 1rem;
	}
}
@media(max-width:1300px) {
	.name-social {
		font-size: 1rem;
	}
	.number-social {
		font-size: 0.9rem;
		margin-top: 2px;
	}
}
@media(max-width:560px) {
	.w-60-sm {
		width: 60px;
	}
	.text-over-p {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 130px;
	}
}
.dash-carousel-image {
	background-position: center center;
	min-height: 215px;
	background-size: cover;
}
// dashboard-2 table 
.widget-table {
	.badge {
		font-size: 11px;
		padding: 4px 7px;
	}
	.email-id a,
	.phone-no a {
		color: $body-color;
		&:hover {
			text-decoration: underline;
		}
	}
	table td {
		vertical-align: middle;
	}
	table {
		th,
		td {
			border-left: 0;
			border-right: 0;
		}
		th {
			vertical-align: middle;
			padding: 1rem;
		}
	}
}

// support-wrap

.support-wrap{
   .support-image{
      width:50px;
   }
   .support-content{
      width: calc(100% - 125px);
   }
   .support-action{
      width:75px;
   }
}

