@font-face {
    font-family: 'Exo';
    src: url('Exo-Bold.eot');
    src: url('Exo-Bold.eot?#iefix') format('embedded-opentype'),
        url('Exo-Bold.woff2') format('woff2'),
        url('Exo-Bold.woff') format('woff'),
        url('Exo-Bold.ttf') format('truetype'),
        url('Exo-Bold.svg#Exo-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Carter One';
    src: url('CarterOne.eot');
    src: url('CarterOne.eot?#iefix') format('embedded-opentype'),
        url('CarterOne.woff2') format('woff2'),
        url('CarterOne.woff') format('woff'),
        url('CarterOne.ttf') format('truetype'),
        url('CarterOne.svg#CarterOne') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo';
    src: url('Exo-Light.eot');
    src: url('Exo-Light.eot?#iefix') format('embedded-opentype'),
        url('Exo-Light.woff2') format('woff2'),
        url('Exo-Light.woff') format('woff'),
        url('Exo-Light.ttf') format('truetype'),
        url('Exo-Light.svg#Exo-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo';
    src: url('Exo-Medium.eot');
    src: url('Exo-Medium.eot?#iefix') format('embedded-opentype'),
        url('Exo-Medium.woff2') format('woff2'),
        url('Exo-Medium.woff') format('woff'),
        url('Exo-Medium.ttf') format('truetype'),
        url('Exo-Medium.svg#Exo-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo';
    src: url('Exo-Italic.eot');
    src: url('Exo-Italic.eot?#iefix') format('embedded-opentype'),
        url('Exo-Italic.woff2') format('woff2'),
        url('Exo-Italic.woff') format('woff'),
        url('Exo-Italic.ttf') format('truetype'),
        url('Exo-Italic.svg#Exo-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Exo';
    src: url('Exo-SemiBold.eot');
    src: url('Exo-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Exo-SemiBold.woff2') format('woff2'),
        url('Exo-SemiBold.woff') format('woff'),
        url('Exo-SemiBold.ttf') format('truetype'),
        url('Exo-SemiBold.svg#Exo-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo';
    src: url('Exo-Regular.eot');
    src: url('Exo-Regular.eot?#iefix') format('embedded-opentype'),
        url('Exo-Regular.woff2') format('woff2'),
        url('Exo-Regular.woff') format('woff'),
        url('Exo-Regular.ttf') format('truetype'),
        url('Exo-Regular.svg#Exo-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

