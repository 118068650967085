
:root {
  --widthSidebar: 240px;
  --widthMiniSidebar: 40px;
  --success: #63c980;
  --success-md: #90d8a4;
  --success-l: #dbf1e1;
  --info: #3086e4;
  --info-md: #6ba8eb;
  --info-l: #d0e3f7;
  --warning: #f2a830;
  --warning-md: #f5c06b;
  --warning-l: #faead0;
  --danger: #ee6f54;
  --danger-md: #f29884;
  --danger-l: #f9ded8;
}

body .ql-container .ql-editor {
  min-height: 60px !important;
}

/* Define width classes from 1% to 100% */
.w-1 {
  width: 1% !important;
}

.w-2 {
  width: 2% !important;
}

.w-3 {
  width: 3% !important;
}

.w-4 {
  width: 4% !important;
}

.w-5 {
  width: 5% !important;
}

.w-6 {
  width: 6% !important;
}

.w-7 {
  width: 7% !important;
}

.w-8 {
  width: 8% !important;
}

.w-9 {
  width: 9% !important;
}

.w-10 {
  width: 10% !important;
}

.w-11 {
  width: 11% !important;
}

.w-12 {
  width: 12% !important;
}

.w-13 {
  width: 13% !important;
}

.w-14 {
  width: 14% !important;
}

.w-15 {
  width: 15% !important;
}

.w-16 {
  width: 16% !important;
}

.w-17 {
  width: 17% !important;
}

.w-18 {
  width: 18% !important;
}

.w-19 {
  width: 19% !important;
}

.w-20 {
  width: 20% !important;
}

.w-21 {
  width: 21% !important;
}

.w-22 {
  width: 22% !important;
}

.w-23 {
  width: 23% !important;
}

.w-24 {
  width: 24% !important;
}

.w-25 {
  width: 25% !important;
}

.w-26 {
  width: 26% !important;
}

.w-27 {
  width: 27% !important;
}

.w-28 {
  width: 28% !important;
}

.w-29 {
  width: 29% !important;
}

.w-30 {
  width: 30% !important;
}

.w-31 {
  width: 31% !important;
}

.w-32 {
  width: 32% !important;
}

.w-33 {
  width: 33% !important;
}

.w-34 {
  width: 34% !important;
}

.w-35 {
  width: 35% !important;
}

.w-36 {
  width: 36% !important;
}

.w-37 {
  width: 37% !important;
}

.w-38 {
  width: 38% !important;
}

.w-39 {
  width: 39% !important;
}

.w-40 {
  width: 40% !important;
}

.w-41 {
  width: 41% !important;
}

.w-42 {
  width: 42% !important;
}

.w-43 {
  width: 43% !important;
}

.w-44 {
  width: 44% !important;
}

.w-45 {
  width: 45% !important;
}

.w-46 {
  width: 46% !important;
}

.w-47 {
  width: 47% !important;
}

.w-48 {
  width: 48% !important;
}

.w-49 {
  width: 49% !important;
}

.w-50 {
  width: 50% !important;
}

.w-51 {
  width: 51% !important;
}

.w-52 {
  width: 52% !important;
}

.w-53 {
  width: 53% !important;
}

.w-54 {
  width: 54% !important;
}

.w-55 {
  width: 55% !important;
}

.w-56 {
  width: 56% !important;
}

.w-57 {
  width: 57% !important;
}

.w-58 {
  width: 58% !important;
}

.w-59 {
  width: 59% !important;
}

.w-60 {
  width: 60% !important;
}

.w-61 {
  width: 61% !important;
}

.w-62 {
  width: 62% !important;
}

.w-63 {
  width: 63% !important;
}

.w-64 {
  width: 64% !important;
}

.w-65 {
  width: 65% !important;
}

.w-66 {
  width: 66% !important;
}

.w-67 {
  width: 67% !important;
}

.w-68 {
  width: 68% !important;
}

.w-69 {
  width: 69% !important;
}

.w-70 {
  width: 70% !important;
}

.w-71 {
  width: 71% !important;
}

.w-72 {
  width: 72% !important;
}

.w-73 {
  width: 73% !important;
}

.w-74 {
  width: 74% !important;
}

.w-75 {
  width: 75% !important;
}

.w-76 {
  width: 76% !important;
}

.w-77 {
  width: 77% !important;
}

.w-78 {
  width: 78% !important;
}

.w-79 {
  width: 79% !important;
}

.w-80 {
  width: 80% !important;
}

.w-81 {
  width: 81% !important;
}

.w-82 {
  width: 82% !important;
}

.w-83 {
  width: 83% !important;
}

.w-84 {
  width: 84% !important;
}

.w-85 {
  width: 85% !important;
}

.w-86 {
  width: 86% !important;
}

.w-87 {
  width: 87% !important;
}

.w-88 {
  width: 88% !important;
}

.w-89 {
  width: 89% !important;
}

.w-90 {
  width: 90% !important;
}

.w-91 {
  width: 91% !important;
}

.w-92 {
  width: 92% !important;
}

.w-93 {
  width: 93% !important;
}

.w-94 {
  width: 94% !important;
}

.w-95 {
  width: 95% !important;
}

.w-96 {
  width: 96% !important;
}

.w-97 {
  width: 97% !important;
}

.w-98 {
  width: 98% !important;
}

.w-99 {
  width: 99% !important;
}

.w-100 {
  width: 100% !important;
}


.my-pagination .ngx-pagination {
  text-align: center;
  margin-top: 0rem !important;
}

button:focus {
  outline: none !important;
}

.cursur-pointer {
  cursor: pointer;
}

app-home {
  background: #fff;
  display: block;
}

.header-top {
  background: #fff;
  display: block;
  float: left;
  width: 100%;
  box-shadow: 0px 3px 5px -3px rgba(171, 171, 171, 1);
  -webkit-box-shadow: 0px 3px 5px -3px rgb(171 171 171);
  -moz-box-shadow: 0px 3px 5px -3px rgba(171, 171, 171, 1);
}

app-main-header {
  position: sticky;
  z-index: 2;
  top: 0;
}

.site-id-area {
  display: flex;
  float: left;
  align-items: center;
}

.account-area {
  display: flex;
  float: right;
  align-items: center;
  height: 55px;
}

.site-id-area h2 {
  margin: 0;
  font-weight: 700;
  font-size: 15px;
  display: flex;
  align-items: center;
  line-height: 1;
}

.site-id-area h2 svg {
  margin-right: 6px;
  width: 15px;
  height: auto;
  position: relative;
  top: -1px;
}

.main-logo {
  width: 225px;
  height: 55px;
  line-height: 60px;
  font-weight: bold;
  font-size: 30px;
  margin-right: 30px;
  cursor: pointer;
  border-right: 1px solid #e5e5e5;
}

.main-logo sup {
  font-size: 13px;
  position: relative;
  top: -15px;
}

.main-logo img {
  width: 100%;
  max-width: 182px;
  height: auto;
}

.main-logo,
.main-logo * {
  background: #2C3252;
  background: -webkit-linear-gradient(to right, #2C3252 0%, #3C3A83 50%, #FF582E 100%);
  background: -moz-linear-gradient(to right, #2C3252 0%, #3C3A83 50%, #FF582E 100%);
  background: linear-gradient(to right, #2C3252 0%, #3C3A83 50%, #FF582E 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.custom-btn-1 {
  all: unset;
  padding: 5px 25px;
  box-sizing: border-box;
  background: linear-gradient(90deg, #2C3252 0%, #3C3A83 0.02%, #FF582E 186.41%);
  border: 1px solid #2EC0FF;
  border-radius: 35px;
  color: #fff;
  cursor: pointer;
  transition: all .2s;
  font-size: 14px;
  position: relative;
  line-height: initial;
}

.custom-btn-1:hover {
  padding-right: 30px;
}

.custom-btn-1:hover:after {
  content: '\203A';
  position: absolute;
  right: 15px;
  top: 47%;
  transform: translateY(-50%);
  width: auto !important;
}

.custom-btn-2 {
  all: unset;
  padding: 5px 15px;
  box-sizing: border-box;
  background: #3C3A83;
  border: 1px solid #3C3A83;
  border-radius: 35px;
  color: #fff;
  cursor: pointer;
  transition: all .2s;
  font-size: 14px;
  position: relative;
  line-height: initial;
  text-align: center;
}

.custom-btn-2:hover {
  background: #6a416f;
  transform: scale(0.95);
}

.pull-right.custom-btn-2:hover {
  transform: none;
}


.custom-btn-20 {
  all: unset;
  padding: 3px 8px;
  box-sizing: border-box;
  background: #3C3A83;
  border: 1px solid #3C3A83;
  border-radius: 35px;
  color: #fff;
  cursor: pointer;
  transition: all .2s;
  font-size: 14px;
  position: relative;
  line-height: initial;
  text-align: center;
}

.custom-btn-20:hover {
  background: #6a416f;
  transform: scale(0.95);
}

.pull-right.custom-btn-20:hover {
  transform: none;
}

.manage-account {
  color: #fff;
  background: #3C3A83;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #2EC0FF;
  cursor: pointer;
}

.manage-projects {
  color: #fff;
  background: #3C3A83;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #2EC0FF;
  cursor: pointer;
}

#dropdownManageProjects:after,
#dropdownManageAccount:after {
  display: none;
}

.manage-account#dropdownManageAccount svg path {
  fill: none;
}

.manage-projects svg path {
  fill: #ffffff;
}

.manage-account svg path {
  stroke: #ffffff;
}

app-icon-svg.fill svg path {
  stroke: #fff0;
  fill: #fff;
}

app-icon-svg.stroke svg path {
  stroke: #fff;
  fill: #fff0;
}


app-icon-svg {
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-dropdown-style {
  background: transparent !important;
  padding: 0px !important;
  margin: 0 !important;
  border: 0 !important;
}

.list-dropdown-items {
  background: #3c3a83;
  padding: 5px 0px;
  margin: 5px;
  border-radius: 5px;
}

.custom-dropdown-style a {
  color: #fff !important;
  display: flex;
  align-items: center;
  padding: 2px 15px 2px 10px;
  margin: 4px 0;
  white-space: nowrap;
  background: #394689;
}

.custom-dropdown-style a svg {
  width: 100vw;
  height: auto;
  max-height: 18px;
  max-width: 16px;
}

.custom-dropdown-style a app-icon-svg {
  margin-right: 7px;
}

.custom-dropdown-style a:hover {
  background: #211f79;
}

.dropdown:hover>.dropdown-menu {
  display: block;
}

.dropdown>.dropdown-toggle:active {
  pointer-events: none;
}

.banner-text {
  font-weight: 700;
  font-size: 3vw;
  max-width: 40vw;
  margin: auto;
  margin-bottom: 40px;
}

.banner-text .color-1 {
  color: #FF3426;
}

.banner-text .color-2 {
  color: #710063;
}

.banner-text .color-3 {
  color: #00B4B6;
}


button.custom-btn-1.large,
button.custom-btn-1.large:after {
  font-size: 35px;
  line-height: 1;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  width: 320px;
}

button.custom-btn-1.larger,
button.custom-btn-1.larger:after {
  font-size: 35px;
  line-height: 1;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  width: 410px;
}

.banner-image img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.banner-image {
  padding-right: 40px;
}

.bounce-fly {
  -webkit-animation: bounce-fly 10s ease-in-out infinite alternate-reverse;
  animation: bounce-fly 10s ease-in-out infinite alternate-reverse;
}

@-webkit-keyframes bounce-fly {
  0% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }

  50% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  100% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

@keyframes bounce-fly {
  0% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }

  50% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  100% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

.main-title-style {
  font-family: "Carter One";
  font-size: 65px;
  color: #2C3252;
}

.main-title-style.main-title-style-small {
  font-size: 50px;
  margin-bottom: 40px;
}

.main-paragraph-style {
  font-size: 29px;
  text-align: justify;
  line-height: 1.4;
}

.about-image img {
  display: block;
  max-width: 75%;
  margin: auto;
  border-radius: 15px;
  border: 1px solid #dfdfdf;
}


.bloc-about-background {
  position: relative;
  background: #fff;
  padding: 20px;
  margin-top: 90px;
}

.bloc-about-background div {
  position: relative;
  z-index: 1;
}

.bloc-about-background:after {
  content: '';
  position: absolute;
  z-index: 0;
  background: #F8F8F8;
  width: 100%;
  height: 50%;
  bottom: 0;
  left: 0;
  border-top: 2px solid #e3e3e3;
}

.bg-grey-light {
  background: #f8f8f8;
}

.bg-explore-studio-bloc {
  background: #F3F7F7;
}

.explore-studio-bloc-btn-container {
  padding: 100px 20px;
  width: 100%;
  max-width: 90%;
  margin: auto;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}

.explore-studio-bloc-btn-container * {
  position: relative;
  z-index: 2;
}

.text-explore-studio-bloc {
  font-size: 45px;
  color: #fff;
  max-width: 690px;
  margin: auto;
}

.capabilities-bloc-container {
  max-width: 90%;
  margin: auto;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px
}

::-webkit-scrollbar-track {
  background: #e1e1e1
}

::-webkit-scrollbar-thumb {
  background: #710063;
  border-radius: 2px
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #2C3252 0%, #3C3A83 0.02%, #FF582E 186.41%)
}

chankya-layout {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: column;
  min-height: 100vh;
}

app-main-footer {
  margin-top: auto;
}

.session-input-font {
  border-radius: 4px;
}

.login-register-background {
  background: linear-gradient(15deg, #2C3252 0%, #3C3A83 0.02%, #FF582E 186.41%);
  color: #fff;
  height: calc(100vh - 110px);
  display: flex;
  align-items: center;
}

.card.card-custom-1 {
  width: 100%;
  padding: 15px;
  border-radius: 8px;
  flex-direction: initial;
  flex-wrap: wrap;
}

.card.card-custom-1 p {
  width: 100%;
}

.card.card-custom-2 {
  min-height: calc(100vh - 160px);
  padding: 10px;
  background: #fafbfe;
  border: 0;
}

.sidebar-menu-wrapper {
  width: 0;
  position: relative;
  z-index: 1;
  background: white;
  transition: all .2s;
  transform: translateX(-100%);
  overflow: hidden;
  border: 0;
}

.sidebar-menu-wrapper.is-open {
  width: var(--widthSidebar);
  overflow: auto;
  border-right: 1px solid #e5e5e5;
  transform: translateX(0);
}

.sidebar-menu-wrapper.is-closed {
  width: var(--widthMiniSidebar);
  overflow: auto;
  border-right: 1px solid #e5e5e5;
  transform: translateX(0);
}

.row-content-area {
  display: flex;
  flex-wrap: wrap;
  min-height: calc(100vh - 90px);
  height: 100%;
  position: relative;
  z-index: 1;
}

.side-bar-opener {
  position: absolute;
  z-index: 1;
  width: 35px;
  height: 35px;
  top: 10px;
  left: -18px;
  background: #3c3a83;
  border-radius: 50%;
  color: #fff;
  border: 1px solid #ccc;
  font-size: 11px;
  padding: 10px 2px 10px 18px;
  cursor: pointer;
  transition: all .2s;
  transform: translateX(0);
}

.side-bar-opener:hover {
  transform: translateX(3px);
}

.side-bar-opener.is-open {
  padding: 10px 2px 10px 17px;
  left: calc(var(--widthSidebar) - 15px);
}

.side-bar-opener.is-closed {
  padding: 10px 2px 10px 17px;
  left: calc(var(--widthMiniSidebar) - 15px);
}

.main-content-wrapper {
  width: 100%;
  transition: all .2s;
}

.main-content-wrapper.dashboard-content {
  padding: 20px;

}

.main-content-wrapper.is-open {
  width: calc(100% - var(--widthSidebar));
}

.main-content-wrapper.is-closed {
  width: calc(100% - var(--widthMiniSidebar));
}

.copyright {
  border-top: 1px solid #f3f3f3;
  position: relative;
  z-index: 1;
  font-size: 12px;
}

.sidebar-menu-container a {
  display: flex;
  color: #3C3A83 !important;
  font-weight: 600;
  flex-wrap: wrap;
  padding: 4px 10px;
}

.sidebar-menu-container {
  padding-top: 10px;
}

.sidebar-menu-container a app-icon-svg svg {
  width: 100vw;
  height: auto;
  max-width: 16px;
  max-height: 18px;
}

.sidebar-menu-container a app-icon-svg.fill svg path {
  fill: #3C3A83;
}

.sidebar-menu-container a app-icon-svg {
  margin-right: 8px;
}

.sidebar-menu-container a app-icon-svg.stroke svg path {
  stroke: #3C3A83;
}

.sidebar-menu-container a.is-active {
  background: #eef2fd;
}

.sidebar-menu-container a:hover {
  background: #dde5fb;
}

.stepper-project-creation-container {
  width: calc(100% - 20px);
  max-width: 800px;
  margin: auto;
  border: 1px solid #dfdfdf;
  padding: 15px;
  margin-top: 15px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.step-trigger-project-creation {
  display: flex;
  margin: 0 5px;
  font-weight: 500;
  font-size: 17px;
  padding: 5px 10px;
  align-items: center;
  justify-content: center;
  color: #3c3a83;
  cursor: pointer;
  transition: all .2s;
  border-radius: 5px;
  opacity: 0.6;
}

.step-trigger-project-creation app-icon-svg svg {
  width: 100vw;
  max-width: 20px;
  height: auto;
  max-height: 22px;
  margin-right: 7px;
  margin-bottom: 2px;
}

.step-trigger-project-creation app-icon-svg.stroke svg path {
  stroke: #3c3a83;
}

.step-trigger-project-creation app-icon-svg.fill svg path {
  fill: #3c3a83;
}

.step-trigger-project-creation.disabled {
  opacity: 0.6 !important;
}

.step-trigger-project-creation:hover,
.step-trigger-project-creation.current,
.step-trigger-project-creation.filled {
  opacity: 1;
}

.steps-project-creation-container {
  width: auto;
  height: auto;
  min-height: 300px;
  min-width: 920px;
  max-width: 100%;
  margin: auto;
  border: 1px solid #dfdfdf;
  padding: 15px;
  margin-top: 15px;
  border-radius: 10px;
  position: relative;
  background-color: #fff;
}

.steps-project-creation-container form {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}

.steps-project-creation-container form .from-project-creation-step {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 50px;
}

.steps-project-creation-container form .from-project-creation-step .form-group {
  width: 100%;
  margin: 0;
}

.steps-project-creation-container.data-model-is-active {
  min-width: 95%;
}

span.required-label {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 3px 9px;
  color: #3c3a83;
  background: #fbf1f1;
  border-radius: 0px 8px 0px 10px;
  font-size: 14px;
  border: 1px solid #dfdfdf;
}

button.next-step-project-creation {
  position: absolute;
  bottom: 0;
  right: 0;
}

button.prev-step-project-creation {
  position: absolute;
  bottom: 0;
  left: 0;
}

button.custom-btn-3 {
  all: unset;
  display: block;
  margin: 0;
  padding: 0;
  float: left;
  cursor: pointer;
}

.project-schema {
  border: 1px dashed #c3c2d9;
  padding: 15px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
}

.project-schema-entities .list table {
  max-width: 100%;
}

.search-entity-in-project-data-model {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #dee4e8;
  border-radius: 5px;
  padding: 7px 10px;
}

.search-entity-in-project-data-model input {
  all: unset;
  width: calc(100% - 40px) !important;
}

.search-entity-in-project-data-model i {
  color: #bfaeec;
  font-size: 14px;
  cursor: pointer;
  transition: all .2s;
}

.search-entity-in-project-data-model i:hover {
  color: #3c3a83;
}

.data-model-entities-table.table-striped tbody tr:nth-of-type(odd) {
  background: #f9f9ff;
}

.data-model-entities-table {
  border-radius: 5px;
}

.data-model-entity-name svg {
  width: 13px;
  height: auto;
  stroke: #3c3a83;
  position: relative;
  top: -1px;
  margin-right: 3px;
}

.versions-table.table-striped tbody tr:nth-of-type(odd) {
  background: #fbfbfb;
}

.cursor-pointer {
  cursor: pointer;
}

.project-schema-entities .list table i {
  font-size: 17px;
  display: inline-flex;
  width: auto;
  align-items: center;
  justify-content: center;
  margin: 5px;
}

.modal-backdrop {
  background-color: #000000 !important;
  opacity: 0.5 !important;
}

.project-schema-entities pagination-controls ul {
  padding: 0 !important;
  margin-bottom: 30px;
}

.project-schema-entities pagination-controls ul .current,
.project-schema-entities pagination-controls ul li {
  color: #3c3a83;
  background: transparent;
}

button.btn-close {
  border: 0;
  background: none;
  padding: 0 !important;
  font-size: 25px;
}

.modal-content {
  padding: 15px !important;
}

.line-height-1 {
  line-height: 1 !important;
}

hro-localized-input,
hro-localized-text-area {
  width: 100%;
}

.custom-btn-10 {
  all: unset;
  padding: 5px 15px;
  box-sizing: border-box;
  background: #3c3a83;
  border: 1px solid #dee4e8;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  transition: all .2s;
  font-size: 14px;
  position: relative;
  line-height: initial;
  text-align: center;
}

.custom-btn-10:hover {
  padding-right: 20px;
}

.custom-btn-4 {
  all: unset;
  padding: 7px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #dee4e8;
  border-radius: 5px;
  color: #3C3A83;
  cursor: pointer;
  transition: all .2s;
  font-size: 14px;
  position: relative;
  line-height: initial;
  text-align: center;
  margin: 0 !important;
}

.custom-btn-4:hover {
  color: #fff;
  background: #3c3a83;
}

.save-cancel-container {
  border-top: 1px solid #DEE4E8;
  padding-top: 15px;
}

.stepper-forms-mat {
  min-height: 700px;
}

.mat-horizontal-content-container {
  padding: 0 !important;
}

.stepper-forms-mat-blue-print-version {
  min-height: 600px;
  display: flex;
  flex-direction: column;
}

.p-inputtext {
  box-shadow: none !important;
}

.hover-reveal-items * {
  opacity: 0;
}

tr:hover .hover-reveal-items * {
  opacity: 1;
}

.copyright sup {
  font-size: 11px;
}

.cdk-column-actions * {
  opacity: 0;
}

mat-row:hover .cdk-column-actions * {
  opacity: 1;
}

.mat-column-actions {
  max-width: 110px !important;
  padding-right: 10px !important;
  justify-content: end;
}

.toast-container .ngx-toastr:hover {
  box-shadow: none;
}

.toast-container .ngx-toastr {
  box-shadow: none !important;
  background: #fff;
  color: #2C3252 !important;
  display: flex;
  width: 400px !important;
  padding: 15px !important;
  align-items: center;
  border: 1px solid #000;
  border-radius: 20px !important;
  font-size: 15px;
  font-weight: bold;
}

.toast-container .ngx-toastr:before {
  display: flex;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 50%;
  color: #000;
  margin-right: 15px;
  font-size: 20px;
}

.toast-container .ngx-toastr.toast-success:before {
  content: "\f00c";
  background: var(--success);
  color: #fff;
}

.toast-container .ngx-toastr.toast-success {
  background: var(--success-l);
  border-color: var(--success-md);
}

.toast-container .ngx-toastr.toast-info:before {
  content: "\f129";
  background: var(--info);
  color: #fff;
}

.toast-container .ngx-toastr.toast-info {
  background: var(--info-l);
  border-color: var(--info-md);
}

.toast-container .ngx-toastr.toast-warning:before {
  content: "\f12a";
  background: var(--warning);
  color: #fff;
}

.toast-container .ngx-toastr.toast-warning {
  background: var(--warning-l);
  border-color: var(--warning-md);
}

.toast-container .ngx-toastr.toast-error:before {
  content: "\f05e";
  background: var(--danger);
  color: #fff;
}

.toast-container .ngx-toastr.toast-error {
  background: var(--danger-l);
  border-color: var(--danger-md);
}

.custom-btn-10 .custom-btn-10-icon {
  opacity: 0;
  left: 0;
  font-size: 0;
  transition: all .2s;
  position: relative;
  font-weight: bold;
}

.custom-btn-10:hover .custom-btn-10-icon {
  opacity: 1;
  left: 8px;
  font-size: inherit;
}

.questions-tree {
  background: transparent !important;
}

.questions-tree .option-tree-node {
  padding: 5px 10px 5px 0px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  background: #6F6DC5;
  border-radius: 5px;
  border-left: 10px solid #2f2cab;
  color: #fff;
  font-size: 15px;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.4);
  width: 100%;
  flex-wrap: wrap;
}

.questions-tree .option-tree-node .action-tree-area {
  width: 165px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.questions-tree .option-tree-node .action-tree-area * {
  margin: 3px;
  cursor: pointer;
}

.questions-tree .option-tree-node .action-tree-area .fa {
  background: #fff;
  display: flex;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.questions-tree .option-tree-node .action-tree-area .fa:hover {
  background: #3c3a83;
  color: #fff !important;
}

.questions-tree .option-tree-node .action-tree-area span:hover {
  border-bottom: 1px solid #ccc;
}








.questions-tree .question-tree-node {
  padding: 5px 10px 5px 0px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  background: #E7E7F5;
  border-radius: 5px;
  border-left: 10px solid #8582DA;
  color: #3C3A83;
  font-size: 15px;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.4);
  width: 100%;
  flex-wrap: wrap;
}

.questions-tree .question-tree-node .action-tree-area {
  width: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.questions-tree .option-tree-node .content-tree-area {
  width: auto;
  flex-grow: 1;
}

.questions-tree .question-tree-node .content-tree-area {
  flex-grow: 1;
  width: auto;
}

.questions-tree .question-tree-node .action-tree-area * {
  margin: 3px;
  cursor: pointer;
}

.questions-tree .question-tree-node .action-tree-area .fa {
  background: #fff;
  display: flex;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.questions-tree .question-tree-node .action-tree-area .fa:hover {
  background: #3c3a83;
  color: #fff !important;
}

.questions-tree .question-tree-node .action-tree-area span:hover {
  border-bottom: 1px solid #ccc;
}


.image-of-blueprint {
  display: flex;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
}

.image-of-blueprint img {
  width: auto;
  height: auto;
  max-height: 40px;
  max-width: 100%;
  border-radius: 5px;
}

.header-of-blueprint-accordion {
  display: flex;
  align-items: center;
  width: calc(100% - 100px);
}

.attribute-of-blueprint {
  font-size: 15px;
  font-weight: 500;
  color: #3c3a83;
  width: calc(100% - 50px);
  padding: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.attribute-of-blueprint:last-child {
  margin-right: 0;
}

.mat-expansion-panel.blueprint-width-versions-accordion {
  margin-bottom: 15px !important;
  width: calc(50% - 20px);
  padding: 5px !important;
  background: #ffffff;
  border-radius: 10px !important;
  border: 1px solid #e2e3ff;
}

.blueprint-width-versions-accordion-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.actions-of-blueprint-accordion i {
  width: 25px;
  height: 25px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #e6e5f0;
  border-radius: 50%;
  cursor: pointer;
  margin: 3px;
}

.versions-table {
  border-radius: 5px 5px !important;
  overflow: hidden;
  background: #ffffff;
  color: #3c3a83;
  border: 1px solid #e2e3ff;
  margin: 0;
  font-size: 14px;
}

.actions-of-blueprint-accordion,
.versions-table tr td.actions-of-blueprint-accordion {
  text-align: right;
  width: 100px !important;
  padding: 10px 2px !important;
}

.versions-table tr td {
  border-bottom: 1px solid #e2e3ff;
  padding: 10px !important;
}

.versions-table thead {
  background: #edf1ff;
}

.versions-table thead th {
  padding: 10px;
}

.versions-table tr:last-child td {
  border-bottom: 0;
}

.blueprint-width-versions-accordion mat-expansion-panel-header .mat-expansion-indicator {
  padding: 10px 10px 15px 10px;
}

.blueprint-width-versions-accordion mat-expansion-panel-header {
  flex-direction: row-reverse;
  padding: 0;
  background: transparent !important;
}

.blueprint-width-versions-accordion mat-expansion-panel-header .mat-expansion-indicator:after {
  color: #3c3a83;
  width: 9px;
  height: 9px;
  border-width: 0 2px 2px 0;
}

.mat-expansion-panel.blueprint-width-versions-accordion:hover {
  background: #fafbfe;
}

.mat-expansion-panel.blueprint-width-versions-accordion .actions-of-blueprint-accordion i {
  opacity: 0;
}

.mat-expansion-panel.blueprint-width-versions-accordion:hover .actions-of-blueprint-accordion i {
  opacity: 1;
}

.versions-table tr td.actions-of-blueprint-accordion i {
  opacity: 0 !important;
}

.versions-table tr:hover td.actions-of-blueprint-accordion i {
  opacity: 1 !important;
}

.no-data-found-msg {
  background: #cccbf7;
  padding: 10px;
  border-radius: 11px;
  color: #fff;
  text-align: center;
}

pagination-controls.custom-pagination {
  display: flex;
  justify-content: center;
}

pagination-controls.custom-pagination ul {
  padding: 0;
  margin: 0 10px;
  display: flex;
}

pagination-controls.custom-pagination ul li.pagination-previous,
pagination-controls.custom-pagination ul li.pagination-next {
  display: none;
}

pagination-controls.custom-pagination ul li {
  background: #d6d6e7;
  border-radius: 5px !important;
  overflow: hidden;
}

pagination-controls.custom-pagination ul li.current {
  background: #3c3a83;
}

.versions-table.table-hover tbody tr:hover {
  background-color: #f9faff;
}

.custom-btn-5 {
  all: unset;
  padding: 5px 8px;
  box-sizing: border-box;
  background: #3c3a83;
  border: 1px solid #dee4e8;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  transition: all .2s;
  font-size: 14px;
  position: relative;
  line-height: initial;
  text-align: center;
  transform: scale(1);
}

.custom-btn-5:hover {
  transform: scale(0.95);
}

a.bjs-powered-by {
  display: none;
}

.versions-table tr td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.versions-table tr td:first-child {
  max-width: 140px;
}

.step-trigger-project-creation.current {
  background: rgb(244, 244, 255);
  background: -moz-radial-gradient(circle, rgba(244, 244, 255, 1) 0%, rgba(231, 233, 255, 0.2945553221288515) 50%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-radial-gradient(circle, rgba(244, 244, 255, 1) 0%, rgba(231, 233, 255, 0.2945553221288515) 50%, rgba(255, 255, 255, 0) 100%);
  background: radial-gradient(circle, rgba(244, 244, 255, 1) 0%, rgba(231, 233, 255, 0.2945553221288515) 50%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f4f4ff", endColorstr="#ffffff", GradientType=1);
}

.img-size-sm {
  max-height: 2.5rem;
  max-width: 2.5rem;
}

button.custom-btn-200 {
  all: unset;
  padding: 5px 15px;
  box-sizing: border-box;
  background: #edf1ff;
  border: 1px solid #e2e3ff;
  border-radius: 4px;
  color: #3c3a83;
  cursor: pointer;
  transition: all .2s;
  font-size: 14px;
  line-height: initial;
  text-align: center;
  float: right;
  margin-bottom: 10px;
}

button.custom-btn-200:hover {
  background: #e2e3ff;
}

.p-autocomplete-panel.p-component {
  z-index: 1100 !important;
}

.modal-model-entity-title {
  background: #edf1ff;
  margin: 0;
  margin-top: 17px;
  border-radius: 5px 5px 0 0;
  border: 1px solid #e2e3ff;
  border-bottom: 0;
  padding: 8px 5px 6px 5px;
  position: relative;
  z-index: 1;
  top: 3px;
}

.tree-modeler-list {
  border-radius: 10px;
  border: 1px solid #e2e3ff;
  overflow: hidden;
}

.tree-modeler-list mat-tree-node {
  color: #3c3a83;
  padding: 0;
  padding-right: 10px;
  font-weight: 700;
}

.tree-modeler-list mat-tree-node .mat-icon-button {
  height: 50px !important;
  background: #f2f2ff;
  color: #3c3a83;
  border-radius: 6px;
}

.tree-modeler-list mat-tree-node:first-child {
  border: 0;
  background: #fff;
}

.tree-modeler-list mat-tree-node {
  border-top: 1px solid #e2e3ff;
  background: #f2f2ff;
}

.builder-h-spinner .loading-text {
  top: 50% !important;
}

.preview-btn-bh {
  margin-right: 0px;
  padding: 5px 25px !important;
  box-sizing: border-box;
  background: linear-gradient(90deg, #2C3252 0%, #3C3A83 0.02%, #FF582E 186.41%);
  border: 1px solid #2EC0FF;
  border-radius: 35px;
  color: #fff !important;
  cursor: pointer;
  transition: all .2s;
  font-size: 14px;
}

.tree-modeler-list {
  width: calc((100% / 3) - 10px);
}

.tree-modeler-list i {
  color: #3c3a83 !important;
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: 50%;
  font-size: 13px;
  background: #fff;
}

.tree-modeler-list i:hover {
  background: #3c3a83;
  color: #fff !important;
}

.custom-textarea-blueprint-container textarea {
  min-height: 125px !important;
}


