//Table of Content

/*--------------
1.0 Bootstrap
----------------*/
/*--------------
2.0 Mixin
----------------*/
/*--------------
3.0 Core
   3.1 Main Content
   3.2 Scaffolding
   3.3 Button
   3.4 Form Elements
   3.5 Progress Bar
   3.6 Tabs
   3.7 Accordions
   3.8 Pagination
   3.9 Cards
   3.10 Typography
   3.11 Badge
   3.12 Dropdowns
   3.13 Social
   3.14 Table
   3.15 Ribbon
   3.16 Header
   3.17 Sidebar
   3.18 Preloader
   3.19 Dash-section
   3.20 Timeline
   3.21 Dark
   3.22 Rtl
----------------*/
/*--------------
4.0 Elements
   4.1 Effect
   4.2 Slick
   4.3 Session
   4.4 User
   4.5 Pricing
   4.6 Blog
   4.7 Gallery
   4.8 Ecommerce
   4.9 Icons
   4.10 Datepicker
   4.11 Calendar
   4.12 Grid
   4.13 Testimonial
   4.14 Inbox
   4.15 Editor
   4.16 Sidebar-option
   4.17 Animate
   4.18 Tour
   4.19 Courses
   4.20 Video-player
   4.21 Pages
----------------*/
/*--------------
5.0 Utilities
   5.1 Bg
   5.2 Base
   5.3 Font Text
----------------*/

@import "bootstrap";
@import "mixins";
@import "core";
@import "elements";
@import "utilities/utilities";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';



.autocompleteSelectClass {
  margin-bottom: 1rem;
}

.no-data-tab {
  font-size: 24px;
  position: absolute;
  left: 35%;
  top: 50%;
}

:ng-deep div.mat-expansion-panel-body {
  margin-top: 0.5%;
  padding-right: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
}

mat-form-field {
  //font-size: 14px;
  width: 100%;
}

.p-treenode-content-selected {
  .fa-folder-o:before {
    font-size: 24px !important;
    color: #5c8fd7 !important;
  }

  .fa-folder-open-o:before {
    font-size: 24px !important;
    color: #5c8fd7 !important;
  }
}

.ng-valid[required],
.ng-valid.required {
  border-left: 5px solid green;
  /* green */
}

.mat-expansion-panel {
  box-shadow: none !important;
  padding: 8px 10px !important
}

.mat-expansion-panel-body {
  padding: 8px 10px !important
}

.ng-invalid:not(form) {
  border-left: 5px solid red;
  /* red */
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #1862c6 !important;
}

.p-tree {
  border: 0px !important;
  font-size: 19px !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-label {
  font-size: $font-size-base !important;
  font-family: "Exo", sans-serif !important;
  background-color: rgba(255, 255, 255, 0.03);
  color: #848484;

}

.pi-caret-right:before {
  content: "\e932" !important;
  color: #848484d1;
  font-weight: inherit;
}

.pi-caret-down:before {
  content: "\e930" !important;
  color: #848484d1;
  font-weight: inherit;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-label.p-state-highlight {
  background-color: transparent !important;
  font-weight: bold;
  font-size: 1rem !important;
  font-family: "Exo", sans-serif !important;
  color: #5992C7 !important;
}

.p-breadcrumb-content-selected {
  outline: 0;
}

.mat-row,
.mat-header-row {
  //min-width: 1123px;
  width: 100%;
}

.mat-table {
  padding: 5px;
  ;
  overflow-x: auto;
  overflow-y: hidden;
  font-family: $font-family-sans-serif;
  box-sizing: border-box;
  margin-bottom: 16px;
  padding: 0;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 5px 10px rgb(0 0 0 / 15%), 0 0 0 rgb(0 0 0 / 8%) inset;
  transition: box-shadow .2s linear, border-color .2s linear, background-color .2s linear;
}

.mat-header-cell {
  font-size: $font-size-base;
  font-family: $font-family-sans-serif;
}

.colorHeader {
  //background-color: #c4dcf3 !important;
  color: #0b3c5d;
}

.mat-header-row {
  min-height: 50px;
  border-bottom-color: rgba(0, 0, 0, 0.74);
}

.mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.18) !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: $primary !important;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: $primary !important;
}

mat-row:hover {
  background: #f5f5f5;

  .action-icon {
    visibility: visible;
  }
}

mat-row:active {
  background: #efefef;
}

.mat-paginator-container {
  height: 40px;
  min-height: 45px !important;
  justify-content: center !important;
  margin-top: 10px;
}

.mat-paginator {
  background: transparent;
}

.details-label {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 10%;
}

.details-data {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-right: 5%;
}

.div-label {
  width: 30%;
}

.div-data {
  width: 50%;
}

.details {
  font-weight: bold;
  color: #007ad9;
  font-size: 18px;
}

.status {
  color: white;
  font-weight: bold;
  border: 1px solid #888888;
  border-radius: 10%;
}

.status-pending {
  background-color: red
}

.status-inprogress {
  background-color: #F8C51C
}

.status-closed {
  background-color: #28a745
}

.width-label {
  width: 15%;
}

.align {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.description {
  display: flex;
  flex-direction: row;
}

.margin-status {
  margin-left: 20px;
}

.mat-tab-body-content {
  overflow-x: hidden !important;
  padding: 20px 20px !important;
  min-height: max-content;
}

table th,
table td {
  border: 0px solid #DEE4E8;
  padding: 0.3rem;
}

.fa-2x {
  font-size: larger !important;
}

.badge-true {
  text-transform: uppercase;
  background-color: #67b373 !important;
}

.badge-false {
  text-transform: uppercase;
  background-color: #D4604F !important;
}

.switch-large {
  width: 70px !important;
  height: calc(2.25rem + 2px) !important;
}

.switch-large small {
  width: 27px !important;
  height: 20px !important;
  border-radius: 20px !important;
}

.switch-large.checked small {
  left: 27px !important;
}

ui-switch {
  display: inline-flex;
  position: relative;
  top: 5px;
  margin: 0 7px;
}

.switch-large {
  width: 55px !important;
  height: 20px !important;
  border-radius: 40px;
}

.switch-large small {
  width: 27px !important;
  height: 20px !important;
  border-radius: 20px;
}

.switch-small {
  width: 70px !important;
  height: 15px;
}

.switch-small.checked {
  width: 70px !important;
  height: 15px;
  background: #67b373 !important;
}

.switch-small small {
  height: 15px;
  width: 15px;
}

.switch-small.checked small {
  left: 50px !important;
  width: 15px !important;
  height: 15px !important;
  top: 2px;
}

.switch-small small {
  width: 15px !important;
  height: 15px !important;
  top: 2px !important;
  left: 4px !important;
}

.switch-small .on {
  margin-top: -5%;
  font-weight: bold;
}

.switch-small .off {
  margin-top: -5%;
  font-weight: bold;
}

.switch .off {
  color: #f8f9fa !important;
}

.p-grid-col-2 {
  width: 16.66666%;
}

.p-breadcrumb {
  border: none !important;
  font-size: small !important;
  margin-bottom: 0px !important;
  padding: 0 0.5rem !important;
  font-family: $font-family-sans-serif !important;

  ul li .p-breadcrumb-link {
    color: #848484 !important;
  }
}

p-menuitem-text {
  font-weight: 400 !important;
  color: #0b3c5d !important;
}

.p-breadcrumb-chevron::before {
  font-weight: 400 !important;
  font-size: 0.7em !important;
  // content: "/" !important;
}

.p-breadcrumb-chevron {
  display: inline !important;
}

hr {
  margin-top: 0px;
  margin-bottom: $hr-margin-y;
  border: 0;
  border-top: $hr-border-width solid $black;
}

body {
  font-family: $font-family-sans-serif !important;
}

.btn-success-color {
  background-color: #67b373 !important;
  border-color: #67b373 !important;
  text-transform: uppercase !important;
}

.no-data {
  font-size: 24px;
  text-align: center;
  margin-top: 10%;
  // position: absolute;
  // left: 50%;
  // top: 50%;
}

.mat-tab-group {
  font-family: $font-family-sans-serif !important;
  font-size: $font-size-base !important;
  margin-top: 10px;

}

.mat-tab-label {
  font-family: $font-family-sans-serif !important;
  font-size: $font-size-base !important
}

.my-pagination .ngx-pagination {
  text-align: center;

  margin-top: 0 !important;

  .current {
    margin-top: 1%;
  }
}

.p-breadcrumb .p-menuitem-text {
  vertical-align: middle;
}

.p-breadcrumb ul li .p-menuitem-link {
  color: #848484 !important;
  text-decoration: none;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: transparent !important;
  color: #848484 !important;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: #848484 !important;
}

.p-autocomplete {
  width: 100% !important
}

.p-inputtext {
  width: 100%;
  border: 1px solid #DEE4E8 !important;
  //height: 41px;
}

.input-width {
  width: 70%
}

.input-wrapper {
  height: 41px;
  width: 90%
}

.input-height {
  height: 41px;
}

.input-text-area-wrapper {
  width: 70%
}

.icons {
  margin-right: 5px;
}

.mat-row-link {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.mat-row {
  position: relative;
}

.a-link {
  color: rgba(0, 0, 0, .87) !important;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 1px;
  width: 0.5rem !important;
  height: 2rem !important;
  color: #a6a6a6;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.back {
  top: 83px;
  margin-right: 15px
}

.example-spacer {
  flex: 1 1 auto;
}

.btnTop {
  position: relative;
  top: -41px;
}

.resizeImage {
  height: 80px;
  width: 80px;
  background: url("/assets/img/image1.png");
  background-repeat: no-repeat;
  background-size: 70px 70px;
  cursor: move;
}

.img:hover {
  .resizeImage {
    background-image: url("/assets/img/uploadImage.png");
    background-repeat: no-repeat;
  }

}

.upload-btn-wrapper input[type=file] {
  font-size: 20px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.shadow:hover {
  cursor: move;

  .action-icon {
    visibility: visible
  }

  a {
    background: red;
  }

  .text-danger {
    color: white !important;
  }
}

.trash-icon-center {
  text-align: center;
  flex-wrap: wrap;
  display: inline-grid;
}

.icon-drag {
  margin: 0;
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  transition: .3s ease;
}

.formStyle {
  background-color: white;
  padding: 20px 20px 15px 20px;
  width: 100%;
  max-width: 1170px;
  margin: auto;
  border-radius: 0.5rem;
  border: 1px solid #ebebf2;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.formStyle .form-group {
  width: calc(50% - 15px);
}

.cell-2 {
  flex: 2;
}

.cell-3 {
  flex: 3;
}

.cell-4 {
  flex: 4;
}

.button-29 {

  padding: 5px 5px;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background-color: #0275d8;
  border: none;
  border-radius: 15px;
  box-shadow: 0 2px rgb(171, 168, 196);
}

.button-29:hover {
  background-color: #0058a5
}

.button-29:active {
  background-color: #0058a5;
  box-shadow: 0 4px rgb(171, 168, 196);
  transform: translateY(2px);
}